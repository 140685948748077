@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1, h2 {
    @apply font-bold;
  }
  h3, h4 {
    @apply font-medium;
    @apply mb-4;
  }
  h1 {
    @apply text-5xl;
  }
  h2 {
    @apply text-4xl;
  }
  h3 {
    @apply text-3xl;
  }
  h4 {
    @apply text-2xl;
  }
}

.App {
  font-family: sans-serif;
  text-align: center;
}
h3 {
  margin-top: 0;
}
td {
  padding: 10px;
  vertical-align: top;
  width: 50%;
}
.row {
  display: flex;
  justify-content: center;
}
